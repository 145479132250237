<template>
  <div class="profile">
    <div class="row justify-content-between align-items-center col-gap-120">
      <div class="col-xxl-4 col-md-5">
        <base-alerts />
        <h1>{{$t('Add Your')}} <br />{{$t('Profile Photo')}}</h1>
        <validation-observer ref="observer" v-slot="{ handleSubmit }">
          <b-form
            @submit.stop.prevent="handleSubmit(uploadImage)"
            enctype="multipart/form-data"
          >
            <div class="profile__Photo" v-if="!facebookAccount">
              <base-profile-upload
                name="Image"
                :rules="`${image == null ? 'required' : 'ext:jpg,png'}`"
                v-model="image"
              />
            </div>
            <div class="profile__Photo" v-if="facebookAccount">
              <base-profile-upload
                name="Image"
                v-model="image"
                ref="file-input"
              />
            </div>
            <p class="subTitle">
              {{$t('register.By continuing you accept our')}} <br />
              <a href="https://glowl.me/tos.html" target="_blank"><span>{{$t('register.Terms and Conditions')}}</span></a>
              {{$t('global.and')}}
              <a href="https://glowl.me/privacy.html" target="_blank"><span>{{$t('register.Privacy Policy')}}</span></a>
            </p>
            <div class="button-row">
              <button
                type="submit"
                class="btn btn-primary large"
                :class="processing ? 'processing' : ''"
              >
                {{ btnText }}
              </button>
            </div>
          </b-form>
        </validation-observer>
      </div>
      <div class="col-xxl-8 col-md-7">
        <div class="image">
          <img
            src="@/assets/images/profile-img.png"
            class="img-fluid"
            alt="profile-img"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { UPLOAD_IMAGE } from "@/graphql/common/mutations";
import register from "../../../mixin/register.js";
import { mapActions } from "vuex";
import { mapGetters } from "vuex";
export default {
  mixins: [register],
  data() {
    return {
      image: null,
      processing: false,
      btnText: this.$i18n.t('register.Finish'),
      facebookAccount: false,
    };
  },
  computed: {
    ...mapGetters({
      user: "user"
    }),
  },
  /*beforeCreate() {
    console.log("Text");
  },
  created() {
    console.log("Text2");
  },*/
  mounted(){
    this.facebookAccount = this.user.login_type === "facebook" ? true : false;
    if (this.facebookAccount) {
      this.setFBProfilePicture().then();
    }
  },
  methods: {
    ...mapActions(["updateUser"]),
    async uploadImage() {
      if (this.user.login_type === "facebook") {
        await this.facebookImageData();
      }
      this.uploadProfilePhoto();
    },
    uploadProfilePhoto() {
      if (this.image != null) {
        this.btnText = this.$i18n.t('Processing')+"...";
        this.processing = true;
      }
      this.$apollo
        .mutate({
          mutation: UPLOAD_IMAGE,
          variables: {
            image: this.image,
          },
        })
        .then((data) => {
          if (data.data.uploadProfile.value != "") {
            this.updateUser().then(() => {
              // this.$router.push("/user/");
              this.$router.push(this.$i18nRoute({name: 'userDashboard'}));
              this.processing = false;
              this.btnText = this.$i18n.t('register.Finish');
            });
            return;
          }
        })
        .catch((e) => {
          this.handleError(e);
          this.processing = false;
          this.btnText = this.$i18n.t('register.Finish');
        });
    },
    async facebookImageData() {
      let imageType = null; 
      const facebookProfileData = JSON.parse(this.user.login_user_data);
      const resp = await fetch(facebookProfileData.picture.data.url);
      const data = await resp.blob();
      if (data.type === "image/jpeg") {
        imageType = "jpg";
      } else if (data.type === "image/png") {
        imageType = "png";
      }
      this.image = new File([data], `${this.user.id}.${imageType}`, {
        type: data.type,
      });
    },
    async setFBProfilePicture() {
      const facebookProfileData = JSON.parse(this.user.login_user_data);

      const resp = await fetch(facebookProfileData.picture.data.url)
      const data = await resp.blob();
      console.log(resp);
      const url = URL.createObjectURL(data.slice(0, 12000))
      
      const parentNode = this.$el.querySelector(".b-form-file"); 
      const img = document.createElement("img");
      img.src = url;
      img.className = "fb-image";
      parentNode.appendChild(img);
    }
  },
};
</script>

<style lang="scss" scoped>
.LoginRegister {
  @media screen and (max-width: 1199px) {
    min-height: calc(100vh + 60px) !important;
  }
  p span {
    font-weight: 700 !important;
  }
  .contentWrapper {
    padding-top: 0 !important;
  }
  .profile {
    &__Photo {
      margin: rem(25px) 0 rem(15px) 0;
    }
    @media screen and (max-width: 1700px) {
      .image {
        img {
          max-width: 540px;
        }
      }
    }
    @media screen and (max-width: 1400px) {
      .image {
        img {
          max-width: 475px;
        }
      }
    }
    @media screen and (max-width: 991px) {
      .image {
        img {
          max-width: 100%;
        }
      }
    }
    @media screen and (max-width: 767px) {
      .button-row {
        justify-content: start;
      }
    }
  }
}
.fb-image {
  width: auto;
  height: auto;
  vertical-align: middle;
}
</style>
